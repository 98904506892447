<template>
    <v-container fluid>
        <v-row>
            <Welcome-Card />
            <Setup-Card />
            
            <v-slide-x-transition hide-on-leave group>
                <v-col cols="12" sm="auto" v-for="(c, ind) in custom" :key="ind" class="my-1 py-0">
                    <v-card v-if="showCustom" class="secondary lighten-1">
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>{{ c.nav != null ? $BlitzIt.navigation.findIcon(c.nav) : c.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ c.text }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn class="primary--text" text :to="{ name: 'setup', query: { step: c.value } }">Setup</v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-slide-x-transition>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'Office-Portal-Home',
        components: {
            SetupCard: () => import('~home/cards/Setup-Card.vue'),
            WelcomeCard: () => import('~home/cards/Welcome-Card.vue')
        },
        data() {
            return {
                showCustom: false
            }
        },
        computed: {
            custom() {
                return !this.showCustom ? [] : [
                    { text: 'Delivery Fees', nav: 'delivery-fees', value: 'delivery-fees' },
                    { text: 'Locations', nav: 'locations', value: 'locations' },
                    { text: 'Products', nav: 'products', value: 'products' },
                    { text: 'Categories', nav: 'product-categories', value: 'product-categories' },
                    { text: 'Pricing', nav: 'supplier-price-tiers', value: 'supplier-pricing' },
                    { text: 'Payment Terms', nav: 'supplier-payment-terms', value: 'supplier-payment-terms' },
                    { text: 'Customer Templates', nav: 'customer-templates', value: 'customer-templates' },
                    { text: 'Customers', nav: 'customers', value: 'customers' },
                    { text: 'Drivers', nav: 'users', value: 'drivers' },
                    { text: 'Journeys', nav: 'journey-templates', value: 'journeys' },
                    { text: 'Order Pipeline', icon: 'mdi-pipe', value: 'order-pipeline' },
                    { text: 'Order Slots', nav: 'order-slots', value: 'order-slots' },
                    { text: 'Supply Pointers', nav: 'supply-pointers', value: 'supply-pointers' }
                ];
            }
        }
    }
</script>